<template>
  <div class="csn-form-data">
    <slot></slot>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  CSN_INPUT_CLASS_SELECTOR,
  EventType,
  InputType,
  EMPTY_STRING,
  FORM_DATA,
  Digit,
} from '@/constants'

const VALUE = 'value'
const CHECKED = 'checked'
const INPUT_SELECTORS = 'input, select, checkbox, textarea'

const validField = { isValid: true, messages: EMPTY_STRING }

export default {
  name: FORM_DATA,
  data() {
    return {
      initialValues: {},
    }
  },
  methods: {
    isInputTypeBoolean(inputType) {
      return inputType === InputType.CHECKBOX || inputType === InputType.RADIO
    },
    reset() {
      this.$el.querySelectorAll(CSN_INPUT_CLASS_SELECTOR).forEach((element) => {
        const field = element.__vue__

        if (!field) {
          return
        }

        const input = element.querySelectorAll(INPUT_SELECTORS)[0]
        field.isReady = false

        if (input.type === InputType.FILE) {
          field?.handleResetButtonClick()
          return
        }

        const attribute = this.isInputTypeBoolean(input.type) ? CHECKED : VALUE
        Vue.set(input, attribute, this.initialValues[field.name])
        input.dispatchEvent(new Event(EventType.INPUT))
      })
    },
    makeReady({ isReady = true } = {}) {
      this.$el.querySelectorAll(CSN_INPUT_CLASS_SELECTOR).forEach((element) => {
        const field = element.__vue__

        if (!field) {
          return
        }

        field.isReady = isReady
      })
    },
    getValidation(fieldName) {
      const formFieldListValidation = {}
      const errors = {}
      let isValid = false
      const selector = fieldName
        ? `${CSN_INPUT_CLASS_SELECTOR}[name="${fieldName}"]`
        : CSN_INPUT_CLASS_SELECTOR

      this.$el.querySelectorAll(selector).forEach(({ __vue__: field }) => {
        if (!field) {
          return
        }

        const { name, validateFormField } = field
        const isHidden = field.$el.hidden

        formFieldListValidation[name] = isHidden
          ? { ...validField }
          : validateFormField()

        errors[name] = formFieldListValidation[name].messages
      })

      isValid = Object.values(formFieldListValidation).every(
        ({ isValid }) => isValid,
      )

      return {
        isValid,
        errors,
      }
    },
  },
  mounted() {
    this.$el.querySelectorAll(CSN_INPUT_CLASS_SELECTOR).forEach((element) => {
      const field = element.__vue__

      if (!field) {
        return
      }

      const input = element.querySelectorAll(INPUT_SELECTORS)[Digit.NOUGHT]

      if (this.isInputTypeBoolean(input.type)) {
        this.initialValues[field.name] = input.checked
        return
      }
      if (input.type === InputType.FILE && input.files.length) {
        this.initialValues[field.name] = input.files[Digit.NOUGHT]
        return
      }

      this.initialValues[field.name] = input.value
    })
  },
}
</script>
